//this is the firebase credential sent by client

export const firebaseConfig = {
  apiKey: "AIzaSyDYfIwhtRqrLe-Di74HylFFapiyM7waH8M",
  authDomain: "good-vibes-cd94b.firebaseapp.com",
  databaseURL: "https://good-vibes-cd94b.firebaseio.com",
  projectId: "good-vibes-cd94b",
  storageBucket: "good-vibes-cd94b.appspot.com",
  messagingSenderId: "606043473275",
  appId: "1:606043473275:web:f045b9d81f6dc44e13a9de",
  measurementId: "G-PQFRXJWZDL"
}

//this is test firebase account

// export const firebaseConfig = {
//   apiKey: "AIzaSyBWOKPPXWaFljgfCYs7zX_ThT3A7IYnU_M",
//   authDomain: "test-web-99098.firebaseapp.com",
//   projectId: "test-web-99098",
//   storageBucket: "test-web-99098.appspot.com",
//   messagingSenderId: "1028484250608",
//   appId: "1:1028484250608:web:75b79f7e437cbd82d33b35",
//   measurementId: "G-Q6QW0SDR2L"
// };
